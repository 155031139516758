<template>
  <b-card>
    <b-row>
      <b-col
        cols="6"
        md="3"
        class="px-0 px-md-2"
      >
        <span>{{ $t('Total time tracked') }}</span>
        <h3> {{ CONVERT_HM(workSummary.totalDuration) }}</h3>
      </b-col>
      <b-col
        cols="6"
        md="3"
        class="px-0 px-md-2"
      >
        <span>{{ $t('Productive time') }} </span>
        <h3>{{ CONVERT_HM(workSummary.productiveDuration) }}</h3>
        <br>
        <p>
          <b-progress
            :value="CALC_PERCENTAGE({
              total: workSummary.totalDuration,
              value: workSummary.productiveDuration
            })"
            max="100"
            variant="success"
            class="progress-bar-success"
          />
        </p>
      </b-col>
      <b-col
        cols="6"
        md="3"
        class="px-0 px-md-2"
      >
        <span>{{ $t('Unproductive time') }}
          <feather-icon icon="InfoIcon" />
        </span>
        <h3>{{ CONVERT_HM(workSummary.unproductiveDuration) }}</h3>
        <br>
        <p>
          <b-progress
            :value="CALC_PERCENTAGE({
              total: workSummary.totalDuration,
              value: workSummary.unproductiveDuration
            })"
            max="100"
            variant="danger"
            class="progress-bar-danger"
          />
        </p>
      </b-col>
      <b-col
        cols="6"
        md="3"
        class="px-0 px-md-2"
      >
        <span>{{ $t('Neutral time') }}
          <feather-icon icon="InfoIcon" />
        </span>
        <h3>{{ CONVERT_HM(workSummary.neutralDuration) }}</h3>
        <br>
        <p>
          <b-progress
            :value="CALC_PERCENTAGE({
              total: workSummary.totalDuration,
              value: workSummary.neutralDuration
            })"
            max="100"
            variant="secondary"
            class="progress-bar-secondary"
          />
        </p>
      </b-col>
      <!-- <b-col>
        <span>Total active users</span>
        <h3>{{ workSummary.totalActive }}</h3>
      </b-col>
      <b-col>
        <span>Total new users</span>
        <h3>1</h3>
      </b-col> -->
    </b-row>
  </b-card>
</template>
<script>
import { BCard, BProgress } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BProgress,
  },
  props: {
    workSummary: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
