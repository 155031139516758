var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',[_c('b-row',[_c('b-col',{staticClass:"px-0 px-md-2",attrs:{"cols":"6","md":"3"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Total time tracked')))]),_c('h3',[_vm._v(" "+_vm._s(_vm.CONVERT_HM(_vm.workSummary.totalDuration)))])]),_c('b-col',{staticClass:"px-0 px-md-2",attrs:{"cols":"6","md":"3"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Productive time'))+" ")]),_c('h3',[_vm._v(_vm._s(_vm.CONVERT_HM(_vm.workSummary.productiveDuration)))]),_c('br'),_c('p',[_c('b-progress',{staticClass:"progress-bar-success",attrs:{"value":_vm.CALC_PERCENTAGE({
            total: _vm.workSummary.totalDuration,
            value: _vm.workSummary.productiveDuration
          }),"max":"100","variant":"success"}})],1)]),_c('b-col',{staticClass:"px-0 px-md-2",attrs:{"cols":"6","md":"3"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Unproductive time'))+" "),_c('feather-icon',{attrs:{"icon":"InfoIcon"}})],1),_c('h3',[_vm._v(_vm._s(_vm.CONVERT_HM(_vm.workSummary.unproductiveDuration)))]),_c('br'),_c('p',[_c('b-progress',{staticClass:"progress-bar-danger",attrs:{"value":_vm.CALC_PERCENTAGE({
            total: _vm.workSummary.totalDuration,
            value: _vm.workSummary.unproductiveDuration
          }),"max":"100","variant":"danger"}})],1)]),_c('b-col',{staticClass:"px-0 px-md-2",attrs:{"cols":"6","md":"3"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Neutral time'))+" "),_c('feather-icon',{attrs:{"icon":"InfoIcon"}})],1),_c('h3',[_vm._v(_vm._s(_vm.CONVERT_HM(_vm.workSummary.neutralDuration)))]),_c('br'),_c('p',[_c('b-progress',{staticClass:"progress-bar-secondary",attrs:{"value":_vm.CALC_PERCENTAGE({
            total: _vm.workSummary.totalDuration,
            value: _vm.workSummary.neutralDuration
          }),"max":"100","variant":"secondary"}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }